import React, { useState, useEffect, useRef } from "react";
import {TRANSITION_DURATION, TYPE_BANNER_BANNER_LARGE} from "../PlaylistConstants";
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Carousel from "react-multi-carousel";
import {Link} from "react-router-dom";
import {URL_CONTENT, URL_WIDGET} from "../../../../../app/src/Components/Routes/Location";
import defaultBanner from '@app/Resources/Images/default_banner.png';

import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import {evaluateItemClass, LIMIT_ITEMS} from "./utils";
import Spinner from 'react-bootstrap/Spinner';
import {responsive, responsivelarge} from '@detail/Pages/DetailPage/CarrouselStyle';
import {responsiveGroup} from "@detail/Pages/DetailPage/CarrouselStyle";
import {useSelector} from "react-redux";
import {actionTypeContainer, generateSlug} from "../../../../../app/src/Utils/utils";

const ListScreenComponent = ({key, className, style, titulo, hasMoreContents, id,
                                  isSmall, typeBanner, playlist, playSpinner, access, titleColor, turnOnLoading
                                  ,contentId = null}) => {

    const [ overOnCard, setOverOnCard ] = useState(false);

    const minimumItems = + playlist?.length< LIMIT_ITEMS ? " BlockCarousel" : " ";

    const getPageId = (w) => {
        return w.screen ? w.screen.id : w.id;
    }

    return (
        <div className="PlaylistContainer CarouselContainer GroupWidget"  key={key} style={{marginBottom: "35px"}}>
            <div className={"Playlist " + className} style={style}>
                <div className="Titulo" style={{display: "flex"}}>
                    <p style={{width: "auto", color: titleColor}}>{titulo}</p>
                    {hasMoreContents &&
                        <Link to={`${actionTypeContainer(access).ROUTE}/${generateSlug(titulo)}/${id}`}
                              style={{padding: "0px 0px 20px 0px", display: "flex", width: "120px"}}
                              className="Titulo button-link-secondary">
                            <div className="ShowMoreText">{t('ficha.showmore')}</div>
                            <FontAwesomeIcon icon={faChevronRight} style={{margin: "4px 0 0 5px"}}/>
                        </Link>}
                </div>
                { playlist?.length > 0 &&
                    <Carousel
                        swipeable={true}
                        draggable
                        showDots={false}
                        responsive={responsiveGroup}
                        infinite={false}
                        autoPlay={false}
                        keyBoardControl={false}
                        transitionDuration={TRANSITION_DURATION}
                        customTransition="transform 1000ms ease-in-out 0s"
                        centerMode={false}
                        containerClass={
                            "carousel-container PlaylistCollection " +
                            typeBanner +
                            " " +
                            minimumItems
                          }
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px CardContainer"
                    >
                        {playlist?.map((w, i) =>
                            <div className={evaluateItemClass({
                                i,
                                overOnCard,
                                playlist,
                                itemClass: "PlaylistItemContainer",
                              })}
                                 key={i} onMouseEnter={()=> setOverOnCard(i)}
                                 onMouseLeave={()=> setOverOnCard(false)}
                            >
                                <Link to={`${actionTypeContainer(access).ROUTE}/${generateSlug(w.title)}/${getPageId(w)}`}
                                      onClick={() => contentId !== w.id && turnOnLoading()}
                                      className="PlaylistItem"
                                      key={w.id + "-" + w.image?.url + w.color}>
                                    <div className="PlaylistItem">
                                        {(!!w.image?.url) &&
                                            <img className="PlaylistItemImage" alt={w.title}
                                                 src={w.image?.url}/>
                                        }
                                        {(w.image?.url === null) &&
                                            <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                                        }
                                        <div className="PlaylistItemTitle">{w.title}</div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </Carousel>}
            </div>
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </div>
    )
};

export default ListScreenComponent;
