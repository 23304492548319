import {
  URL_CONTENT,
  URL_HOME,
  URL_PLAYER,
  URL_SCREEN,
  URL_SECTION,
  URL_VIDEODETAIL,
  URL_WIDGET,
} from "../Components/Routes/Location";
import {
  WIDGET_TYPE_BANNER,
  WIDGET_TYPE_BANNER_CLICK,
  WIDGET_TYPE_FEATURED_V2,
  WIDGET_TYPE_GRID,
  WIDGET_TYPE_GRID_GROUP,
  WIDGET_TYPE_LIVE_V2,
  WIDGET_TYPE_POSTER,
  WIDGET_TYPE_PROGRESS,
} from "../Components/widget_v2/contanst";
import {
  TYPE_BANNER_BANNER,
  TYPE_BANNER_BANNER_LARGE,
  TYPE_BANNER_LIVE,
  TYPE_BANNER_LIVE_2,
  TYPE_BANNER_LIVE_VERTICAL,
  TYPE_BANNER_POSTER,
  TYPE_BANNER_PROGRESS,
  TYPE_BANNER_TEXT,
} from "../../../detail/src/Pages/DetailPage/PlaylistConstants";

export const USER_DEFAULT = "default-web";
export const WIDGET_TYPE_HIGHLIGHT = "DESTACADO";
export const WIDGET_TYPE_LIVE = "LIVE";
export const WIDGET_TYPE_LIVE_2 = "LIVE_2";
export const WIDGET_TYPE_LIVE_VERTICAL = "LIVEVERTICAL";
export const WIDGET_TYPE_GROUP_WIDGET = "GROUPWIDGET";
export const WIDGET_TYPE_GROUP_SCREEN = "SCREENWIDGET";
export const WIDGET_TYPE_CIRCULAR = "CIRCULAR";
export const WIDGET_TYPE_NEWS = "NEWS";
export const WIDGET_TYPE_FEATURED = "FEATURED";
export const WIDGET_TYPE_PROGRESS_V1 = "WIDGET_PROGRESS";

export const DATA_LOADING_STATUS_LOADED = "LOADED";
export const DATA_LOADING_STATUS_LOADING = "LOADING";

export const SWITCHER_WIDTH = 768;

export const TVG = "TVG";

export const ANIMATION_GET_GOAL_PLAY = "playingAnimationGetGoal";
export const ANIMATION_GET_GOAL_ACHIEVEMENTS = "achievements";
export const ANIMATION_GET_GOAL_REWARDS = "rewards";

export const TYPE_REWARD_BADGE = "badge";
export const TYPE_REWARD_CERT = "cert";
export const TYPE_REWARD_HOOK = "hook";

export const WIDGET_ACTION_PLAY = "PLAY";
export const WIDGET_ACTION_DETAIL = "DETAIL";
export const WIDGET_ACTION_FULL_LIST = "FULL_LIST";
export const WIDGET_ACTION_SCREEN = "SCREEN";

export const ROUTES_BY_WIDGET_ACTION = {
  [WIDGET_ACTION_PLAY]: {
    ROUTE: URL_PLAYER,
    TEXT: "seeMore",
    SHOW_BUTTONS: true,
  },
  [WIDGET_ACTION_DETAIL]: {
    ROUTE: URL_VIDEODETAIL,
    TEXT: "seeDetail",
    SHOW_BUTTONS: true,
  },
  [WIDGET_ACTION_FULL_LIST]: {
    ROUTE: URL_CONTENT + URL_WIDGET,
    TEXT: "seeDetail",
    SHOW_BUTTONS: false,
  },
  [WIDGET_ACTION_SCREEN]: {
    ROUTE: URL_SCREEN,
    TEXT: "seeDetail",
    SHOW_BUTTONS: false,
  },
};

export const ANDROID_DEVICES = ["Android"];
export const IOS_DEVICES = ["iPhone", "iPod", "iPad"];

export const DEVICE_IPHONE = "iPhone";
export const DEVICE_IPAD = "iPad";
export const DEVICE_IPOD = "iPod";
export const DEVICE_ANDROID = "Android";
export const DEVICE_IOS = "iOS";

export const DEVICES = {
  [DEVICE_ANDROID]: DEVICE_ANDROID,
  [DEVICE_IPHONE]: DEVICE_IOS,
  [DEVICE_IPOD]: DEVICE_IOS,
  [DEVICE_IPAD]: DEVICE_IOS,
};

export const VIDEO_TYPE_VOD_360 = "VOD_360";
export const VIDEO_TYPE_LIVE = "LIVE";
export const VIDEO_TYPE_LIVE_TIIVII = "LIVE_TIIVII";
export const VIDEO_TYPE_VOD_FORKED = "VOD_FORKED";
export const VIDEO_TYPE_VOD_CONTAINER = "CONTAINER";
export const VIDEO_TYPE_VOD_AR = "AR";
export const VIDEO_TYPE_VOD_VR = "VR";
export const VIDEO_TYPE_PDF = "PDF";

export const TIME_ZONE_EUROPE_BERLIN = "Europe/Berlin";
export const DEFAULT_TIME_ZONE = TIME_ZONE_EUROPE_BERLIN;

export const SHOW_LENGUAJE = "lenguaje";
export const SHOW_SEARCH = "search";
export const ESCAPE_KEY = "Escape";

export const TYPE_BANNER_CONVERSION = {
  circular: WIDGET_TYPE_CIRCULAR,
  banner: TYPE_BANNER_BANNER,
  "banner-v2": WIDGET_TYPE_BANNER,
  "grid-v2": WIDGET_TYPE_GRID,
  widget_progress: TYPE_BANNER_PROGRESS,
  "widget_progress-v2": WIDGET_TYPE_PROGRESS,
  featured: WIDGET_TYPE_FEATURED,
  "featured-v2": WIDGET_TYPE_FEATURED_V2,
  banner_large: TYPE_BANNER_BANNER_LARGE,
  "directo-v2": WIDGET_TYPE_LIVE_V2,
  live: TYPE_BANNER_LIVE,
  "live-2": TYPE_BANNER_LIVE_2,
  "live-vertical": TYPE_BANNER_LIVE_VERTICAL,
  "filter-list": WIDGET_TYPE_GRID_GROUP,
  filter: WIDGET_TYPE_GRID,
  poster: TYPE_BANNER_POSTER,
  "poster-v2": WIDGET_TYPE_POSTER,
  news: TYPE_BANNER_TEXT,
  DESTACADO: WIDGET_TYPE_HIGHLIGHT,
  LIVE: TYPE_BANNER_LIVE,
  BANNER: TYPE_BANNER_BANNER,
  "FILTER-LIST": WIDGET_TYPE_GRID_GROUP,
  DEFAULT_TYPE: WIDGET_TYPE_GRID,
};

export const widgetTypesWithoutSeparateSpace = [
  WIDGET_TYPE_HIGHLIGHT,
  WIDGET_TYPE_GRID,
  WIDGET_TYPE_LIVE_V2,
  WIDGET_TYPE_POSTER,
  WIDGET_TYPE_PROGRESS,
  WIDGET_TYPE_BANNER,
  WIDGET_TYPE_FEATURED_V2,
  WIDGET_TYPE_BANNER_CLICK,
];
