import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";
import { URL_SEARCH, URL_USER, URL_LOGIN, URL_HOME } from "@components/Routes/Location";
import { User } from "@user/Context/User";
import { user, home } from "@core";
import { BarraBusquedaMobile } from "@app/Pages/Menu/components/barraSuperior/components/BarraBusquedaMobile";
import iconTrophy from "@app/Resources/Images/Icon-trophy.svg";

import "./BarraSuperior.css";
import { PointsAnimation } from "./components/PointsAnimation";

import MenuLiveComponent from "./components/MenuLiveComponent";
import { SHOW_LENGUAJE, SHOW_SEARCH, SWITCHER_WIDTH } from "../../../../Utils/Constants";
import useOutput from "../../../../Utils/Hooks/useOutput";
import { toArray } from "../../../../Components/Kotlin/KotlinUtils";
import { compareUserProfileObjects } from "../../../../Utils/utils";
import { MENU_SCREEN_ID_HOME } from "../../MenuConstants";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { LanguagesComponent } from "./components/LanguagesComponent";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { STATE_CONTENT_RESET } from "../../../../../../home/src/Pages/Home/HomeComponentConstants";
import { useSelector } from "react-redux";
import { URL_NAME_HOME } from "../../../../Components/Routes/Location";
import { getFileService } from "../../../../services/services";

export function BarraSuperiorSimple() {
  return <BarraSuperior clase="BarraSuperiorSimple" />;
}

export const BarraSuperior = (props) => {
  const { clase, sections, homeParentController, handleChangeSection, userAppController } = props;
  const history = useHistory();
  const buttonUser = sections.filter((section) => section.url === URL_USER)[0];
  const buttonSearch = sections.filter((section) => section.url === URL_SEARCH)[0];

  const outputFn = useOutput();
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();
  const { rol, reloadUser } = useContext(User);

  const homeSection = useSelector((state) => state.commonState.homeSection);

  const [renderMobileVersion, setRenderMobileVersion] = useState(window.innerWidth <= SWITCHER_WIDTH);
  const [controller, setController] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState(null);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [homeController, setHomeController] = useState(null);

  const [userURLAvatar, setUserURLAvatar] = useState("");

  const { userData } = useSelector((state) => state.commonState);

  useEffect(() => {
    let homeCon = homeParentController ? homeParentController : new home.controller();
    let homeBinding = homeCon.bind(homeModel, (o) => outputFn(o, homeOutput, home));
    setHomeController(homeCon);
    if (window.location.pathname === URL_SEARCH) {
      setShowSearch(true);
    }
    return () => homeCon.unbind(homeBinding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelShow = (value) => {
    /*if (!availableLanguages || availableLanguages?.length === 0) return;*/
    if (value === SHOW_LENGUAJE) {
      setShowLanguage(!showLanguage);
      setShowSearch(false);
    } else {
      if (value === SHOW_SEARCH) {
        setShowSearch(!showSearch);
        setShowLanguage(false);
      }
    }
  };

  const homeModel = () => {};

  const homeOutput = () => {};

  useEffect(() => {
    const con = userAppController;
    let binding = con.bind(model, (o) => outputFn(o, output, user));
    con.dispatch(user.events.LogUserProfileView);
    setController(con);

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const model = (o) => {
    const formattedModel = {};
    formattedModel.loading = o.loadingUser.name$;
    if (formattedModel.loading === STATE_CONTENT_RESET) {
      if (keycloak?.authenticated) {
        setTimeout(handleGoToLogin, 600);
        if (window.location.pathname !== URL_HOME) {
          history.push(window.location.pathname);
        }
      }
    } else {
      if (reloadUser) {
        reloadUser();
      }
    }
  };
  const output = () => {};

  function findSectionWithHomeName() {
    const sectionWithHome = sections.find((item) => item.section.name.toUpperCase().includes(URL_NAME_HOME));
    if (sectionWithHome) {
      const { section, url } = sectionWithHome;
      const { screenId } = section;
      return { screenId, url };
    } else {
      return null;
    }
  }

  const handleGoToHome = () => {
    const ev = new home.events.LoadScreen(MENU_SCREEN_ID_HOME);
    homeController.dispatch(ev);
    handleChangeSection(homeSection, ev);
    if (window.location.pathname !== URL_HOME) {
      const sectionIdUrl = findSectionWithHomeName();
      localStorage.setItem("OTT-URLSection", sectionIdUrl.url);
      localStorage.setItem("OTT-SectionID", sectionIdUrl.screenId);
      history.push(URL_HOME);
    }
  };

  const handleGoToLogin = () => {
    if (reloadUser) {
      reloadUser();
    }
  };

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const isLogin = () => {
    return initialized && keycloak.authenticated;
  };

  const getFileCallback = (response) => {
    setUserURLAvatar(response.data.data.url);
  };

  useEffect(() => {
    if (!!userData?.avatar) {
      const userToken = keycloak?.token;
      getFileService(MULTITENANT_CONSTANTS[TENANT].tenantServer, userData.avatar, userToken, getFileCallback);
    }

  }, [userData]);

  return (
    <div className={"BarraSuperior " + (!!clase && clase)}>
      <div onClick={handleGoToHome} className={"LogoContainer"}>
        <div className="Logo-Barra" />
      </div>
      <div className={"groupOptions"}>
        {buttonSearch && (
          <div className="SearchBarWrapper">
            <div className="SearchBarContainer">
              <BarraBusquedaMobile buttonSearch={buttonSearch} show={showSearch} handelShow={handelShow} />
            </div>
          </div>
        )}
        {buttonUser && keycloak?.authenticated && (
          <div className="Usuario">
            <div className={"UserSeccion"}>
              <User.Consumer>
                {(User) =>
                  User && (
                    <Link to={URL_USER}>
                      <img className="AvatarMenu" src={userURLAvatar} alt="Usuario" />
                      {MULTITENANT_CONSTANTS[TENANT].gamification && User?.points && (
                        <div className="RankingMenu">
                          <img className="TrophyMenu" src={iconTrophy} alt="IconTropy" />
                          <PointsAnimation userPoints={User.points} />
                        </div>
                      )}
                    </Link>
                  )
                }
              </User.Consumer>
            </div>
          </div>
        )}

        {buttonUser &&
          !keycloak?.authenticated &&
          (!renderMobileVersion || (renderMobileVersion && MULTITENANT_CONSTANTS[TENANT]?.showLoginButtonInMobile)) && (
            <div className="Usuario">
              {renderMobileVersion ? (
                <div onClick={handleGoToLogin} className={"LoginButton"}>
                  <ion-icon name="log-in-outline" />
                </div>
              ) : (
                <NavLink to={URL_LOGIN} className={"LoginButton"}>
                  {t("user.login")}
                </NavLink>
              )}
            </div>
          )}

        {
          <LanguagesComponent
            controller={controller}
            show={showLanguage}
            handelShow={handelShow}
            renderMobileVersion={renderMobileVersion}
          />
        }

        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          placement="bottom"
          overlay={(props) => <Tooltip {...props}>{t("subtitle.LIVE")}</Tooltip>}
        >
          <MenuLiveComponent
            userAge={rol?.maxAge}
            rol={rol}
            theme={keycloak.clientId}
            sections={sections}
            homeController={homeController}
            handleChangeSection={handleChangeSection}
          />
        </OverlayTrigger>
      </div>
    </div>
  );
};
