import React, { useEffect, useState } from "react";
import { BarraSuperior } from "./components/barraSuperior/BarraSuperior";
import "./Menu.css";
import { menu, user } from "@core";
import {SECTION_URL_BY_NAME, URL_HOME, URL_SCREEN, URL_SECTION} from "../../Components/Routes/Location";
import MenuComponent from "./components/menuComponent/MenuComponent";
import { DATA_LOADING_STATUS_LOADED, SWITCHER_WIDTH } from "../../Utils/Constants";
import { NotificationsProvider } from "./Context/NotificationsProvider";
import useOutput from "../../Utils/Hooks/useOutput";
import { compareUserProfileObjects, generateSlug } from "../../Utils/utils";
import {
  selectMenuSection,
  selectMenuSectionId,
  storeHomeSectionActionCreator,
  storeSectionsMenuActionCreator,
} from "../../actions/commonActions";
import { useDispatch } from "react-redux";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";
import { getMenu, logUserAnalyticService } from "../../services/services";
import { useKeycloak } from "@react-keycloak/web";
import { analyticsConstants, PAGE_VIEW_ACTION, LOGIN_VALUE } from "../../analyticsConstants/analyticsConstants";

function MenuApiComponent(props) {
  const { homeController, userAppController, menuAppController } = props;
  const dispatch = useDispatch();
  const outputFn = useOutput();
  const { keycloak } = useKeycloak();

  const [controller, setController] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [renderMobileVersion, setRenderMobileVersion] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const [sectionsMenu, setSectionsMenu] = useState([]);

  useEffect(() => {
    const con = menuAppController;
    let binding = con?.bind(menuModel, (o) => outputFn(o, menuOutput, menu));
    const username = keycloak?.tokenParsed?.preferred_username;
    const analyticKey = analyticsConstants[PAGE_VIEW_ACTION].key;
    const analyticValue = analyticsConstants[PAGE_VIEW_ACTION].value[LOGIN_VALUE];
    if (!!username) {
      logUserAnalyticService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        username,
        PAGE_VIEW_ACTION,
        analyticKey,
        analyticValue,
      );
    }

    setController(con);

    return () => {
      con?.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuModel = (o) => {
    const formattedModel = {};

    formattedModel.activatedNotifications = true;
    formattedModel.loading = o.loadingSections.name$;

    if (o.sections) {
      const as = [];
      const it = o.sections.iterator();

      while (it.hasNext()) {
        const a = {};
        a.section = it.next();
        a.url = SECTION_URL_BY_NAME[a.section.type.name$.toUpperCase()] || URL_HOME;

        as.push(a);
      }

      formattedModel.sections = as;
    }

    if (o.studioUrl) {
      formattedModel.studioUrl = o.studioUrl;
    }

    if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(menuData, formattedModel)) {
      const homeSections = formattedModel.sections.filter((s) => s.url === URL_HOME);
      if (!!homeSections?.length) {
        const homeSection = homeSections[0].section.iconId;
        dispatch(storeHomeSectionActionCreator(homeSection));
      }

      setMenuData(formattedModel);
    }
  };

  const menuOutput = () => {};

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const handleToggleNotifications = () => {};

  const handleChangeSection = (section, sectionId) => {
    dispatch(selectMenuSection(section));
    dispatch(selectMenuSectionId(sectionId));
  };

  const getSectionUrl = (sectionName, screenId) => {
    const cleanSectionName = generateSlug(sectionName);
    const urlProfile = "profile";
    const urlSearch = "search";
    const urlUser = "user";
    const urlDiscover = "discover";
    const urlAgenda = "agenda";

    const specialURLs = {
      [urlUser]: "user",
      [urlSearch]: "search",
      [urlProfile]: "profile",
    };
    const specialSections = {
      [urlDiscover]: "discover",
    };
    let urlSection = `${URL_SCREEN}/${cleanSectionName}/${screenId}`;
    if (cleanSectionName in specialURLs) {
      urlSection = `/${specialSections[cleanSectionName]}`;
    } else if (cleanSectionName in specialSections) {
      urlSection = `${URL_SECTION}/${cleanSectionName}`;
    }
    return urlSection;
  };


  const buildMenuObjet = (data) => {
    const dataMenu = data.data[0].menu;
    const newDataMenu = dataMenu.map((menu) => {
      const { icon, id, name, screen, translations } = menu.interfacemenus_id;
      const urlSection = name.toLowerCase().includes("home") || name.toLowerCase().includes("search") || name.toLowerCase().includes("profile")
        ? "/" + name
        : getSectionUrl(name, !!screen?.id ? screen?.id : id);
      return {
        section: { iconId: icon, name: name, id: name, screenId: screen?.id, type: { name$: name } },
        url: urlSection,
        translations: translations,
      };
    });
    setSectionsMenu(newDataMenu);
    dispatch(storeSectionsMenuActionCreator(newDataMenu));
  };

  useEffect(() => {
    getMenu(buildMenuObjet);
  }, []);

  return (
    <div className="Menu">
      {menuData && (
        <div>
          <BarraSuperior
            sections={sectionsMenu}
            clase={`BarraSuperiorUsuario ${scrolling && "scrolling"} ${
              MULTITENANT_CONSTANTS[TENANT].sticky && "sticky"
            }`}
            homeParentController={homeController}
            handleChangeSection={handleChangeSection}
            {...props}
          />
          <NotificationsProvider>
            {
              /*loadClasses( */
              <MenuComponent
                studioUrl={menuData?.studioUrl}
                controller={controller}
                activatedNotifications={menuData.activatedNotifications}
                handleToggleNotifications={handleToggleNotifications}
                renderMobileVersion={renderMobileVersion}
                homeParentController={homeController}
                handleChangeSection={handleChangeSection}
              />
              /*)*/
            }{" "}
          </NotificationsProvider>
        </div>
      )}
    </div>
  );
}

export default MenuApiComponent;
