import React, { useEffect, useState } from 'react';
import Playlist from "../../../../detail/src/Pages/DetailPage/Playlist";
import {
    selectMenuSection, selectMenuSectionId,
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../app/src/actions/commonActions";
import {useDispatch, useSelector} from "react-redux";

import {
    TYPE_BANNER_CONVERSION, WIDGET_ACTION_DETAIL, WIDGET_ACTION_FULL_LIST,
    WIDGET_TYPE_GROUP_WIDGET,
    WIDGET_TYPE_HIGHLIGHT, widgetTypesWithoutSeparateSpace
} from "../../../../app/src/Utils/Constants";
import {
    ORIGIN_SECTIONS,
    WIDGET_TYPE_GRID, WIDGET_TYPE_GRID_GROUP,
} from "../../../../app/src/Components/widget_v2/contanst";
import {getBannersWidgetServices, getWidgetsServices} from "../../../../app/src/services/services";
import {
    TYPE_BANNER_FEATURED,
} from "../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import {URL_HOME} from "../../../../app/src/Components/Routes/Location";
import {useHistory} from "react-router-dom";

const SectionComponent = (props) => {
    const {scrollRef} = props;
    const dispatch = useDispatch();
    /*const [widgetBannersData, setWidgetBannersData] = useState(null);*/
    const [sectionData, setSectionData] = useState([]);
    const [responseSectionData, setResponseSectionData] = useState(null);
    let isMounted = true;
    const history = useHistory();

    const {sectionSelected, sectionsMenu, sectionSelectedId} = useSelector((state) => state.commonState);

    useEffect(() => {
        //controlamos que existe la section de la url y que coincide con el id
        !!sectionsMenu?.length && urlControl();
    }, [sectionsMenu]);


    const getSectionByPathname = () => {
            const parts = window.location.pathname.split("/");
            const screenId = parts[parts.length - 1];
            dispatch(selectMenuSectionId(screenId));
        return screenId;
    }

    useEffect(() => {
            urlControl();
        return () => {
            isMounted = false;
        };
    }, [sectionSelected]);

    const callbackError = () => {
        localStorage.removeItem("OTT-URLSection");
        localStorage.removeItem("OTT-SectionID");
        history.push(URL_HOME);
    }

    const urlControl = () => {
        const screenId = getSectionByPathname();
       getWidgetsServices(screenId,  callbackGetWidgets)
    }

    const callbackGetBannersWidget = (dataBanners) => {
        if (!!dataBanners) {
            creatObjectToPrint(dataBanners);
        }
    }

    const callbackGetWidgets = (dataWidgets) => {
        if (!!dataWidgets.widgets) {
            setResponseSectionData(dataWidgets);
            scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        } else {
            callbackError();
        }
    }

    useEffect( () => {
        if (!!responseSectionData) {
            Promise.all(responseSectionData.widgets.map(widget => creatObjectSectionToPrint(widget)))
                .then(objectToPrintArray => {
                    setSectionData(objectToPrintArray);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [responseSectionData])

    function widgetBannersData(widgetId) {
        const allBanners = new Promise((resolve, reject) => {
            getBannersWidgetServices(widgetId, dataBanners => {
                resolve(creatObjectToPrint(dataBanners, (data)=> resolve(data)));
            });
        });
        return allBanners;
    }

    const convertBannerType = (dataBannerType) => {
        return dataBannerType === TYPE_BANNER_FEATURED ? WIDGET_TYPE_HIGHLIGHT : dataBannerType
    }

    const creatObjectSectionToPrint = async (dataWidget) => {
        let widgetList = {}
        if(!!dataWidget) {
            const { screens_id, widgets_id } = dataWidget;
            widgetList = {
                id: widgets_id?.id,
                titulo: widgets_id?.title,
                lista: await widgetBannersData(widgets_id?.id)
                    .then((bannerList) => {
                        return bannerList
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    }),
                background: widgets_id.backgroundImage,
                backgroundColor: widgets_id.backgroundColor,
                titleColor: widgets_id.textColor,
                type: convertBannerType(widgets_id.type.toUpperCase()),
                rotateTime: null,
                access: null,
                hasMoreContents: null,
                loadState: null,
            }
        }
        return widgetList;
    }

    const creatObjectToPrint = (databanners) => {
        let creatingData = [];
        !!databanners &&
        databanners.map(banner => (
            creatingData = [...creatingData, createObjectBanner(banner)]
        ))
        return creatingData;
    }

    const createObjectBanner = (banner) => {
        const backgroundImageURL = banner.background?.data?.asset_url ?
            "https://streamer-cdn.ott.tiivii.com/v1/reme/" +banner.background?.data?.asset_url :
            banner.backgroundimage?.data?.full_url;
        const bannerImageURL = banner.banner?.data.asset_url ?
            "https://streamer-cdn.ott.tiivii.com/v1/reme/" + banner.banner?.data.asset_url :
            banner.backgroundimage?.data?.full_url;
        const posterImageURL =  banner.poster?.data?.asset_url ?
            "https://streamer-cdn.ott.tiivii.com/v1/reme/" +  banner.poster?.data?.asset_url :
            banner.backgroundimage?.data?.full_url;

        return ({
            "id": banner.id,
            "title": banner.title,
            "subtitle": banner.subtitle,
            "description": banner.description,
            "category": banner.category?.code,
            "subcategory": banner.subcategory,
            "publishDate": banner.publish_date,
            "tags": banner.tags?.array_hd7ov6$_0,
            "background": {
                "id": banner.background?.id,
                "width": banner.background?.width,
                "height": banner.background?.height,
                "url": backgroundImageURL,
                "type": {
                "name$": "BACKGROUND",
                    "ordinal$": 2
                }
            },
            "banner": {
                "id": banner.banner?.id,
                    "width": banner.banner?.width,
                    "height": banner.banner?.height,
                    "url": bannerImageURL,
                    "type": {
                    "name$": "BANNER",
                        "ordinal$": 1
                }
            },
            "poster": {
            "id": banner.poster?.id,
                "width": banner.poster?.width,
                "height": banner.poster?.height,
                "url": posterImageURL,
                "type": {
                "name$": "POSTER",
                    "ordinal$": 0
            }
        },
            "type": {
            "name$": "CONTAINER",
                "ordinal$": 5
        },
            "progress": 0,
            "canPlay": false,
            "canFav": true,
            "isFav": false,
            "score": 0,
            "startTimeMs": null,
            "image" : {
                "url": bannerImageURL,
            }
        })
    }

    const selectedVideo = () => {
        console.log('selectedVideo')
    }

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    const getBannerType = (sectionData, widgetSection) => {
       let bannerType = WIDGET_TYPE_GRID;
       if (sectionData.length > 1 ||
           TYPE_BANNER_CONVERSION[widgetSection?.type] === WIDGET_TYPE_GROUP_WIDGET ||
           TYPE_BANNER_CONVERSION[widgetSection?.type] === WIDGET_TYPE_GRID_GROUP) {
           bannerType = !!TYPE_BANNER_CONVERSION[widgetSection?.type] ?
               TYPE_BANNER_CONVERSION[widgetSection?.type] :
               TYPE_BANNER_CONVERSION.DEFAULT_TYPE;
       }
       return bannerType;
    }

    const divSeparateSection = (widgetSection, index) => {
        return index > 0 &&
                !!widgetSection?.length ?
                    !!widgetSection.type &&
                        !widgetTypesWithoutSeparateSpace.includes(widgetSection.type) &&
                        <div className="FrameworkHomeBackgroundContainer">
                            <div className="FrameworkHomeBackgroundV2">
                            </div>
                        </div> : <></>
    }

    let defaultBackground = document.getElementById("root").style.getPropertyValue("--bannerWidgetImageColor");
    let defaultBackgroundColor = document.getElementById("root").style.getPropertyValue("--bannerWidgetBgColor");
    let defaultTitleColor = document.getElementById("root").style.getPropertyValue("--rowTitleColor");


    const getURLType =  (typeBanner) => {
        const URLType = typeBanner === WIDGET_TYPE_GRID_GROUP ?  WIDGET_ACTION_FULL_LIST : WIDGET_ACTION_DETAIL
        return URLType;
    }

    return (
        <>
                <div className="Home" key={sectionSelected}>
                        {sectionData?.map((widgetSection, index) => {
                            let background = widgetSection.background || defaultBackground;
                            let backgroundColor = widgetSection.backgroundColor || defaultBackgroundColor;
                            let titleColor = widgetSection.titleColor || defaultTitleColor;
                            const typeBanner = getBannerType(sectionData,widgetSection);
                            const typeURL = getURLType(typeBanner);
                            if (!!widgetSection?.lista.length  ){
                                return <div key={widgetSection?.titulo} className={"containerWidget"} about={widgetSection?.type}>
                                    {
                                        divSeparateSection(widgetSection, index, sectionData)

                                    }
                                    <Playlist
                                        className="FrameworkHome"
                                        clickCallback={selectedVideo}
                                        id={widgetSection?.id}
                                        hasMoreContents={false}
                                        playlist={widgetSection?.lista}
                                        hideSubtitle={true}
                                        titulo={widgetSection?.titulo}
                                        turnOffLoading={turnOffLoading}
                                        turnOnLoading={turnOnLoading}
                                        typeBanner={typeBanner}
                                        access={typeURL}
                                        rotateTime={null}
                                        titleColor={titleColor}
                                        pageLocation={'HOME'}
                                        origin={ORIGIN_SECTIONS}
                                    />
                                </div>
                            }

                            })
                            }
                </div>
        </>
    );
};

export default SectionComponent;
