import React from "react";
import {Link} from "react-router-dom";

import VideoType from "../../../../../detail/src/Pages/DetailPage/VideoType";
import GalleryCardComponent from "../../../../../app/src/Components/common/GalleryCardComponent";
import ContentCardComponent from "../../ContentCardComponent";
import {IMAGE_HEIGHT, IMAGE_WIDTH} from "../../../../CatalogueConstants";
import defaultBanner from '@app/Resources/Images/default_banner.png';
import {URL_VIDEODETAIL} from "../../../../../app/src/Components/Routes/Location";
import {useTranslation} from 'react-i18next';
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {renderImage} from "../../../../../app/src/Utils/utils";


const ContentComponent = ({contents, handleGoToVideoDetail, selectContent, changeShowNoContent, showNoContent}) => {

    const {t} = useTranslation();

    return (contents.map((w, index) => {
            return <div key={w.id}>
                {w.hasChildren() && !w.parentId && !MULTITENANT_CONSTANTS[TENANT].showOnlySons &&
                    <>
                     {showNoContent && changeShowNoContent()}
                    <div className="PlaylistItemContainer VideoContainer"
                     onClick={() => handleGoToVideoDetail(w.id)}>
                    <GalleryCardComponent image={renderImage(w)} width={IMAGE_WIDTH}
                                          height={IMAGE_HEIGHT}/>
                    <div className="contentFooter">
                        <div className="PlaylistItemTitle-Default">{w.title}</div>
                        <VideoType subtitle={w}/>
                        <div className="PlayFavContainer">
                            <div className="boton-play">
                                <Link className="BotonVer button-focused" to={{
                                    pathname: URL_VIDEODETAIL + "/" + w.id,
                                    state: {
                                        url: w.video?.url,
                                        type: w.video?.type,
                                        id: w.id
                                    }
                                }}>
                                    {t('ficha.seeDetail')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                </>}
                {!w.hasChildren() && (MULTITENANT_CONSTANTS[TENANT].showOnlySons || !w.parentId) &&
                   <>
                     {showNoContent && changeShowNoContent()}
                      <ContentCardComponent content={w} defaultBanner={defaultBanner}
                                      handleSelectedMedia={handleGoToVideoDetail}
                                      imageCard={renderImage(w)}/>
                    </>}

            </div>
        }
    ))
};

export default ContentComponent;
