import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import GalleryCardComponent from "../../../../../app/src/Components/common/GalleryCardComponent";
import VideoType from "../../../../../detail/src/Pages/DetailPage/VideoType";
import defaultBanner from '@app/Resources/Images/default_banner.png';
import {IMAGE_HEIGHT, IMAGE_WIDTH} from "../../../../CatalogueConstants";
import '../Catalogo.css';
import {URL_VIDEODETAIL} from "../../../../../app/src/Components/Routes/Location";
import {isContentTypeContainer, renderImage} from "../../../../../app/src/Utils/utils";

const SelectedContentComponent = ({content, handleGoToVideoDetail, selectContent, contents}) => {

    const {t} = useTranslation();

    const handleSelectContent = id => {
        const index = contents.indexOf(contents.find(item => item.id === id))
        selectContent(index)
    };

    return (<>
        {content.length > 0 && content.map((c, index) => c.value.hasChildren() ?
            <div className="PlaylistItemContainer VideoContainer" key={c.value.id}
                 onClick={() => handleSelectContent(c.value.id)}>
                <GalleryCardComponent image={renderImage(c)} width={IMAGE_WIDTH}
                                      height={IMAGE_HEIGHT}/>
                <div className="PlaylistItemTitle">{c.value.title}</div>
                <div className="contentFooter">
                    <VideoType subtitle={c.value}/>
                    <div className="PlayFavContainer">
                        <div className="boton-play">
                            <Link className="BotonVer button-focused" to={{
                                pathname: URL_VIDEODETAIL + "/" + c.value.id,
                                state: {
                                    url: c.value.video?.url,
                                    type: c.value.video?.type,
                                    id: c.value.id
                                }
                            }}>
                                {t('ficha.seeMore')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div> : <div
                className="PlaylistItemContainer VideoContainer" key={c.value?.id}
                onClick={() => handleGoToVideoDetail(c.value?.id)}>
                <GalleryCardComponent image={renderImage(c)}
                                      width={IMAGE_WIDTH} height={IMAGE_HEIGHT}/>
                <div className="contentFooter">
                    <div className="PlaylistItemTitle-Default">{c.value?.title}</div>
                    <VideoType subtitle={c.value}/>
                    <div className="PlayFavContainer">
                        <div className="boton-play">
                            <Link className="BotonVer button-focused" to={{
                                pathname: URL_VIDEODETAIL + "/" + c.value.id,
                                state: {
                                    url: c.value.video?.url,
                                    type: c.value.video?.type,
                                    id: c.value.id
                                }
                            }}>
                                {t('ficha.seeDetail')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>)}
        {content.length === 0 && <div className={"NoChildrenContainer"}>
            <p>{t('catalogue.noContent')}</p>
        </div>
        }
    </>)
};

export default SelectedContentComponent;
