import React, { useState } from "react";
import "../BarraSuperior.css";
import { useTranslation } from "react-i18next";
import { URL_SEARCH } from "../../../../../Components/Routes/Location";
import { updateUserDataService } from "../../../../../services/services";
import { useKeycloak } from "@react-keycloak/web";
import { TENANT, MULTITENANT_CONSTANTS } from "../../../../../Resources/Multitenant/tenantConstants";
import { languageConstants } from "../../../../../languageConstants/languageConstants";
import { useSelector, useDispatch } from "react-redux";
import { setHtmlLang } from "../../../../../Utils/utils";
import { saveLanguageSelected, saveUserDataActionCreator } from "../../../../../actions/commonActions";
import { user } from "@core";

const MenuLanguagesComponent = ({ handleShowLanguagesMenu, showLanguagesMenu, renderMobileVersion }) => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const userData = useSelector((state) => state.commonState.userData);
  const dispatch = useDispatch();
  const [languageSelected, setLanguageSelected] = useState(i18n.language);

  const availableLanguagesRedux = useSelector((state) => state.commonState.tenantLanguages);
  const availableLanguages = availableLanguagesRedux || languageConstants[TENANT];

  const JP = "jp";

  const updateUserDataCallback = (response) => {
    dispatch(saveUserDataActionCreator(response));
  };

  const handleChangeLanguage = (l) => {
    const username = keycloak?.tokenParsed?.preferred_username;
    const userToken = keycloak?.token;
    const userLanguage = { language: l };
    const ev = new user.events.UpdateLanguage(l);
    !!username &&
      updateUserDataService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        username,
        userToken,
        updateUserDataCallback,
        null,
        userLanguage,
      );

    setLanguageSelected(l);
    dispatch(saveLanguageSelected(l));
    localStorage.setItem("petisgo-locale", l);
    i18n.changeLanguage(l);
    setHtmlLang(l);
    handleShowLanguagesMenu();
  };
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };

  return (
    <div
      className={`${languageSelected === JP ? "MenuDesplegable_JP" : "MenuDesplegable"} ${
        showLanguagesMenu && "active"
      } ${isSearchSection() && renderMobileVersion && "belowTheSearch"}`}
    >
      {availableLanguages?.length > 0 &&
        availableLanguages.map((lang, i) => (
          <div
            key={i}
            className={"Button-Language " + (i18n.language && i18n.language.startsWith(lang) ? "Selected" : "")}
            onClick={() => handleChangeLanguage(lang)}
          >
            <span className={i18n.language === "jp" ? "verticalText" : ""}>{t(`lang.min.${lang}`)}</span>
          </div>
        ))}
    </div>
  );
};

export default MenuLanguagesComponent;
